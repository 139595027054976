<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ $t('SmartNavBar.My_repairs') }}</v-toolbar-title>
					<v-toolbar-items class="kmp">
						<v-tabs background-color="transparent" v-model="tabId" class="my-auto" @change="onTabChanged"
							show-arrows hide-slider>
							<v-tab>{{ $t('Repairs.Alle_herstellingen') }}</v-tab>
							<v-tab>{{ $t('Repairs.In_behandeling') }}</v-tab>
						</v-tabs>
					</v-toolbar-items>
					<v-spacer />

					<v-text-field class="my-auto" :placeholder="$t('Your_reference')" flat dense solo light
						v-model="searchValue" append-icon="mdi-magnify" hide-details clearable
						@keyup.native.enter="onSearch" @click:append="onSearch" @click:clear="onClear"
						style="max-width: 250px"></v-text-field>

				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
		</v-row>
		<v-row justify="center" v-for="repair in repairList" :key="repair.herstelnr">
			<v-col cols="12" md="9" lg="7">
				<repairCard :herstelh="repair" />
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-btn v-if="this.rowIdent" :loading="loading" color="primary" @click="getRepairList()">
				<span class="mx-6">{{ $t('Load_more') }}</span>
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import RepairCard from "../components/RepairCard";
export default {
	name: "Repairs",
	components: { RepairCard },
	data() {
		return {
			loading: false,
			tabId: 0,
			repairList: [],
			rowsToBatch: 10,
			rowNum: 0,
			rowIdent: "",
			searchValue: "",
		};
	},
	methods: {
		getRepairList() {
			this.loading = true;
			let params = {
				statusFilter: this.tabId,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
				search: this.searchValue,
			};
			return caas
				.rpc("getPortalUserRepairs", params)
				.then((response) => {
					this.loading = false;
					if (response.data.success.data) {
						this.repairList.push(
							...response.data.success.data.repairList
						);
						this.rowNum = response.data.success.rowNum;
						this.rowIdent = response.data.success.rowIdent;
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error.message);
				})
		},
		onTabChanged() {
			this.repairList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getRepairList();
		},
		onClear() {
			this.searchValue = "";
			this.onSearch();
		},
		onSearch() {
			this.repairList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getRepairList();
		},
	},
	mounted() {
		this.getRepairList();
	},
};
</script>
