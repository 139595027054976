<style scoped>
/* remove underline from text fields */
.no-underline>>>.v-input__slot::before,
.no-underline>>>.v-input__slot::after {
	border-bottom: none !important;
	border-color: white !important;
}

.no-underline>>>.v-input__slot {
	font-size: 14px !important;
}
</style>
<template>
	<v-card outlined rounded>
		<v-card-title>
			<span>{{ $t('RepairCard.Herstelling') }} {{ herstelh.herstelnr }}</span>
			<v-spacer />
			<v-chip label small color="info" outlined>
				<span v-if="herstelh.verwerkt">{{ $t("RepairCard.Afgehandeld") }}</span>
				<span v-else>{{ $t("RepairCard.In_behandeling") }}</span>
			</v-chip>
			<v-spacer />
			<v-btn plain @click.stop="previewDocument">
				<v-icon>mdi-file-document-outline</v-icon>
				<span>pdf</span>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="6">
					<v-text-field class="no-underline" :label="$t('Date')" :value="$d(new Date(herstelh.datum), 'short')"
						readonly hide-details />
				</v-col>
				<v-col cols="6">
					<v-text-field v-show="herstelh.ref" :label="$t('Your_reference')" :value="herstelh.ref" readonly
						class="no-underline" hide-details />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="6">
					<v-text-field :label="$t('Product_code')" :value="herstelh.anr" readonly hide-details />
				</v-col>
				<v-col cols="6">
					<v-text-field v-if="herstelh.serienr" :label="$t('Serial_number')" :value="herstelh.serienr" readonly
						hide-details />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-text-field :label="$t('Product_description')" :value="herstelh.omschrijving" readonly hide-details />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-textarea :label="$t('RepairCard.Defect')" :value="herstelh.info_defect" readonly hide-details />
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field class="no-underline" readonly hide-details :label="$t('Net_total')"
						:value="$n(herstelh.netto, 'decimal') + '  ' + herstelh.muntcode" />
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "RepairCard",
	props: {
		herstelh: Object,
	},
	data() {
		return {};
	},
	methods: {
		previewDocument() {
			let params = {
				documentId: this.herstelh.herstelnr,
				documentType: "HB",
			};
			caas.rpc("getDocumentUrl", params).then((response) => {
				window.open(response.data.success.documentUrl);
			});
		},
	},
};
</script>

